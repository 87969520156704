<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('General') }}</span>
      </template>

      <settings-general
        v-if="options.business"
        :general-data="options.business"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- certificates tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          :icon="options.businessSecurity && options.businessSecurity.certificates.main.type !== '' ? 'ShieldIcon' : 'SlashIcon'"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('Certificates') }}</span>
      </template>

      <settings-certificate
        v-if="options.businessSecurity"
        :certificate-data="options.businessSecurity"
      />
    </b-tab>
    <!--/ certificates tab -->

    <!-- ILL: 110622 SE OCULTA SECCIÓN DE COMPROBANTES -->
    <!--
    <b-tab>      
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('Invoices') }} <b-badge pill variant="info">Coming Soon</b-badge></span>
      </template>

    </b-tab>-->
    <!--/ Invoice Type -->

    <!-- taxable address tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          :icon="options.business && options.business.address && options.business.address.main.zip !== '' ? 'MapIcon' : 'SlashIcon'"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold danger">{{ t('mainadd') }}</span> 
      </template>

      <settings-main-address
        v-if="options.business && options.business.address && options.business.address.main"
        :main-address-data="options.business.address.main"
      />
    </b-tab>
    <!--/ taxable address tab -->

    <!-- branches address tab -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="MapPinIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('altadd') }}</span>
      </template>
      <settings-branches
        v-if="options.business && options.business.branches"
        :branches-list-data="options.business.branches"
      />
    </b-tab>
    <!--/ branches address tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('cpass') }}</span>
      </template>

      <settings-password />
    </b-tab>


    <b-tab @click="goShop" v-if="ecommerceEnabled">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShoppingCartIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ t('buy.tokens') }}</span>
      </template>
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BBadge, BTabs, BTab } from 'bootstrap-vue'
import SettingsGeneral from './SettingsGeneral.vue'
import SettingsCertificate from './SettingsCertificate.vue'
import SettingsMainAddress from './SettingsMainAddress.vue'
import SettingsBranches from './SettingsBranches.vue'
import SettingsPassword from './SettingsPassword.vue'
import SettingsInformation from './SettingsInformation.vue'
import SettingsSocial from './SettingsSocial.vue'
import SettingsNotification from './SettingsNotification.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n' // ILL: 290122
import store from '@/store'

export default {
  components: {
    BBadge,
    BTabs,
    BTab,
    SettingsGeneral,
    SettingsCertificate,
    SettingsMainAddress,
    SettingsBranches,
    SettingsPassword,
    SettingsInformation,
    SettingsSocial,
    SettingsNotification,
  },
  mounted() {
    this.$root.$on('session-refresh',(message) => {
      console.log('Settings session-refresh', message)
      this.$http.post('/account/settings',{}).then(res => { 
        this.options = res.data
        const expDate = res?.data?.businessSecurity?.certificates?.main?.notAfter;
        this.ecommerceEnabled = res?.data?.ecommerce_status;

        let session = JSON.parse(localStorage.getItem('session'))
        /** Override business object */
        session.business = res.data.business
        /** Overwrite session data */
        localStorage.setItem('session', JSON.stringify(session))
                  
        store.commit('csd-advisor/expiry', expDate)
        this.$root.$emit('session-settings-refresh',{})
      })
    })
  },
  setup() {
    /** ILL: 29/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  data() {
    return {
      options: {},
      ecommerceEnabled: false
    }
  },
  methods: {
    goShop(){
      this.$router.push({path:"/apps/e-commerce/personal"})
    }
  },
  beforeCreate() {
    this.$http.post('/account/settings',{}).then(res => { 
      this.options = res.data; 
      const expDate = res?.data?.businessSecurity?.certificates?.main?.notAfter;
      this.ecommerceEnabled = res?.data?.ecommerce_status && res?.data?.business?.address?.main?.zip;

      let session = JSON.parse(localStorage.getItem('session'))
        /** Override business object */
        session.business = res.data.business
        /** Overwrite session data */
        localStorage.setItem('session', JSON.stringify(session))

      store.commit('csd-advisor/expiry', expDate) 
    })
  },
}
</script>
